/*
$bkred: #a80000;
$bkblue: #006565;
$bkgreen: #008600;
$bkbrown: #a84c00;
*/

$bkred: #d12233;
$bkblue: #088c9b;
$bkgreen: #a5b33a;
$bkbrown: #ca8e4c;
$bkgray:  #989898;
$bkblood: #9b0000;
$bkpink: #FF69B4;

.rosie {
    margin-top: 1rem;
}

.book-container {
    font-size: 1.2rem;
}
.ralph-quote {
    font-family: georgia;
    font-size: 1.3rem;
}
.book-even {
    color: #8a0303 ;
    font: bold 2rem Arial;
    font-style: italic;
    margin-top: 1rem;
}
.book-title {
    font: normal 3rem georgia;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.book-lit {
    font: normal 2rem georgia;
    margin-bottom: 1.5rem;
}
.book-section1 {
    font: normal 2.2rem georgia;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.book-section2 {
    font: normal 1.6rem arial;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.book-description {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.book-links {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.book-link {
    margin-right: 2.5rem;
}
.book-link2 {
    margin-right: 0.8rem;
}
.book-span {
    vertical-align: middle;
    font-size: 1rem;
}
.book-span2 {
    vertical-align: 2px;
    font-size: 1rem;
}

.book-cover {
    margin-left: 1rem;
}

.book-footer {
    border-top: lightgray solid 1px;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.book-back-cover {
    color: $bkblue;
}

.book-synopsis {
    color: $bkgreen;
}

.book-the-video {
    color: $bkbrown;
}

.book-the-book {
    color: $bkred;
}

.salvation-crisis {
    color: $bkblood;
}

.book-rosie {
    color: $bkpink;
}

.how-to-use-book {
    color: $bkblue;
}

.contact-info {
    color: $bkgray;
}

.margin-top2 {
    margin-top: 5rem;
}
.show-hide {
    display: inline;
}

.book-button {
    color: white; 
    padding: 1rem; 
    font-size: 1.65rem;
    font-style: italic;
    border-radius: 6px;
}

.book-button-outer {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.signature {
    font-weight: bold;
}
.serving {
    font-style: italic;
}
.book-price {
    vertical-align: middle;
    font-size: 1rem;
    margin-top: 1.3rem;
}

@media (min-width: 500px) {
    .book-button {
        color: white; 
        padding: 1.5rem; 
        font-size: 1.8rem;
        font-style: italic;
        border-radius: 6px;
    }
    .book-button-outer {
        margin-top: 3rem;
        margin-bottom: 4rem;
    }
}

@media (min-width: 768px) {
    .show-hide {
        display: none;
    }
    
    .book-container {
        font-size: 1.4rem;
    }
    .book-footer {
        font-size: 1.4rem;
    }

    .ralph-quote {
        font-family: georgia;
        font-size: 1.5rem;
    }

    .book-even {
        color:#8a0303;
        font: bold 3rem Arial;
        font-style: italic;
        margin-top: 1rem;
    }
    .book-title {
        font: normal 5.5rem georgia;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .book-lit {
        font: normal 3rem georgia;
        margin-bottom: 2rem;
    }
    .book-section1 {
        font: normal 3.2rem georgia;
        margin-bottom: 1rem;
    }
    .book-section2 {
        font: normal 1.8rem arial;
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
    .book-description {
        margin-left: 2rem;
        margin-bottom: 1rem;
    }
    .book-links {
        margin-left: 2rem;
    }
    .book-cover {
        margin-left: 2rem;
    }
    .book-links {
        margin-left: 2rem;
    }
    .book-button {
        font-size: 2.5rem;
    }
    .book-price {
        margin-left: 2rem;
    }    
}

@media (min-width: 992px) {
    .book-section2 {
        margin-right: 20rem;
    }
    .book-description {
        margin-right: 20rem;
    }

}

